import React from 'react';
import './About.css';

function About() {
  return (
    <div className="About">
      <h2>Об агентстве</h2>
      <p>
        История литературного агентства «Нео-Лит» началась до того, как оно было
        зарегистрировано.
      </p>
      <p>
        В июне 2013 года в Барнауле состоялся Пленум Союза писателей России, и среди
        делегатов пленума был замечательный писатель, один из лучших отечественных
        фантастов – Святослав Логинов.
      </p>
      <p>
        Для того чтобы организовать с ним полноценную встречу, литературный
        консультант Алтайского дома литераторов Анна Самойлова создала Клуб любительниц
        фантастики. Первое заседание клуба состоялось 4 апреля 2013 года – достаточно времени,
        чтобы почитать книги Логинова и подготовить вопросы.
      </p>
      <p>
        С этого момента можно отсчитывать историю литературного агентства «Нео-Лит».
      </p>
      <p>К встрече со Святославом Логиновым подготовились, и после того, как она
        состоялась и Святослав Владимирович уехал домой, участницы клуба попросили Анну
        Самойлову продолжать занятия и стать руководителем студии прозы.
      </p>
      <p>
        На занятиях студии Анна Самойлова разработала авторскую методику преподавания
        литературного мастерства, и первые результаты не заставили себя долго ждать: рукопись
        Елены Бызовой выиграла краевой издательский конкурс, и книга «Большое путешествие
        маленькой девочки» была издана за счёт средств краевого бюджета. А уже в 2015 году
        троих студийцев, и Елену в том числе, приняли в Союз писателей России.
      </p>
      <p>
        В апреле 2015 года Алтайский дом литераторов был ликвидирован, но работа Клуба
        любительниц фантастики продолжилась на договорной основе. Более того, Анна
        Самойлова открыла ещё одну студию – «Бездну оптимизма», эта студия начала работать с
        15 января 2015 года.
      </p>
      <p>
        После закрытия Дома литераторов Анна Самойлова осталась без любимой работы.
        Но студийцы попросили продолжать занятия, они готовы были платить.
      </p>
      <p>
        Анна Самойлова написала бизнес-план и получила от Барнаульской службы
        занятости грант на открытие литературного агентства.
      </p>
      <p>
        С тех пор произведения студийцев неоднократно выигрывали в разных конкурсах,
        получали литературные премии: Кристиан Бэд за роман «Дурак космического масштаба»
        получил премию «ДонКон-17», а Валерий Копнинов получил приз «Серебряный Витязь»
        за роман «Двенадцать затмений луны» на X Международном Славянском литературном
        форуме «Золотой Витязь». Евгения Чернова-Гармс выиграла региональный издательский
        конкурс, её повесть для детей «Добрый ноябрь» издана за счёт краевого бюджета.
        Наталью Ревкову и Валерия Копнинова приняли в Союз писателей России.
      </p>
      <p>
        Хвалиться успехами можно бесконечно. Например, у журнала «Фантастическая
        среда», который выпускается литературным агентством «Нео-Лит» с 2014 года, в 2019-м
        появилась своя премия. Она вручается за лучший рассказ, опубликованный в журнале в
        течение года. Первой её получила Наталья Гриневич за рассказ «Модная стрижка».
      </p>
      <p>
        Новый поворот произошёл в 2018 году. Литературное агентство «Нео-Лит» вышло
        на следующий этап развития. Анна Самойлова открыла онлайн-курсы по написанию
        романа: «Система Станиславского для писателей», «Композиция литературного
        произведения» и другие. А также начала вести канал на <a href="https://www.youtube.com/channel/UCGoOmBXpS8AVoRPzNd6nv-Q" target="_blank" rel="noopener noreferrer">YouTube</a>.
      </p>
      <p>
        В сентябре 2019 года силами литературного агентства «Нео-Лит» был организован
        семинар молодых литераторов «Алтай. Нео-Лит». Мастерами семинара стали Мария
        Семёнова, Катя Матюшкина и виртуально присутствовал Дмитрий Емец.
      </p>
      <p>
        В настоящий момент при литературном агентстве «Нео-Лит» работают три студии
        прозы: Клуб любительниц фантастики, «Бездна оптимизма», «Банда литературных
        хулиганов». Есть поэтическая студия, её ведёт Евгения Ткалич – одна из первых участниц
        Клуба любительниц фантастики, она тоже теперь член Союза писателей России. Есть
        Клуб интеллектуальных читателей, где мы обсуждаем самые разные книги, и группа
        психологической поддержки «Красный кофе».
      </p>
    </div>
  );
}

export default About;