import React, {useState, useEffect} from 'react';
import './Blog.css';
import PostPreview from "./PostPreview";
import useInfiniteScroll from "./useInfiniteScroll";

const limit = 10
let offset = 0
let endReached = false


function Blog() {
  const [posts, setPosts] = useState([]);
  useEffect(fetchPostsPage, [])
  const setIsFetching = useInfiniteScroll(fetchPostsPage);

  function fetchPostsPage() {
    if (endReached)
      return;

    fetch(`/api/v1/posts?limit=${limit}&offset=${offset}`)
      .then(response => response.json())
      .then(json => {
        const newPosts = json.posts.map(post => ({...post, publishDateTime: new Date(post.publishDateTime)}));

        if (newPosts.length === 0) endReached = true;
        else setPosts(posts.concat(newPosts));
      })

    setTimeout(() => setIsFetching(false), 1000)
    offset += limit;
  }

  return (
    <div className="Blog">
      {posts.map(post => <PostPreview key={post.slug} post={post} />)}
    </div>
  )
}

export default Blog;
