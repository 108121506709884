import React from 'react';
import './Post.css';

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {postId: props.match.params.postId}
  }

  render() {
    return (
      <div className="Post">
        {this.state.post && <div>
          <h2>{this.state.post.title}</h2>
          <p className="date">{this.state.post.publishDateTime.toLocaleDateString("ru-RU")}</p>
          <div dangerouslySetInnerHTML={{__html: this.state.post.fullText || this.state.post.previewText}}/>
        </div>}
      </div>
    )
  }

  componentDidMount() {
    fetch(`/api/v1/posts/${this.state.postId}`)
      .then(response => response.json())
      .then(json => this.setState({post: {...json, publishDateTime: new Date(json.publishDateTime)}}))
  }
}

export default Post;
