import React from 'react';
import './NotFound.css';

function NotFound() {
  return (
    <p className="NotFound">Страница не найдена</p>
  );
}

export default NotFound;
