import React, {useState, useEffect} from 'react';
import './Magazine.css';

function Magazine() {
  const [magazines, setMagazines] = useState([]);

  useEffect(() => {
    fetch("/api/v1/magazines")
      .then(response => response.json())
      .then(json => setMagazines(json.magazines))
  });

  return (
    <div className="Magazine">
      <h2>Журнал «Фантастическая среда»</h2>
      <p>
        Журнал начал издаваться, как студийный — для публикации произведений участниц студии
        «Клуб любительниц фантастики». Но потом расширил свои границы. Сейчас в журнале публикуются произведения
        русскоязычных авторов независимо от возраста и места проживания.
        Ограничений по жанрам тоже нет. Главное, чтобы было написано интересно.
      </p>
      <p>
        Главный редактор Анна Самойлова.<br />
        Редсовет:<br />
        Н. Ревкова;<br />
        В. Копнинов;<br />
        Е. Бызова;<br />
        Н. Шинакова.<br />
        Технический редактор Н. Гриневич.<br />
        <br />
        Для авторов: <a href="https://storage.yandexcloud.net/neolit/docs/fant_sreda_oferta.pdf" target="_blank">договор
        публичной оферты</a>
      </p>

      {magazines.map(magazine => (
        <a className="number" href={magazine.downloadLink} target="_blank">
          <h3>{`«Фантастическая среда» №${magazine.number}`}</h3>
          <img src={magazine.coverLink} alt={`Номер ${magazine.number}`} />
        </a>
      ))}
    </div>
  )
}

export default Magazine;