import React from 'react';
import './PostPreview.css';
import {Link} from "react-router-dom";

function PostPreview({post}) {
  return (
    <div className="PostPreview">
      <Link className="title" to={`/blog/${post.slug}`}><h2>{post.title}</h2></Link>
      <p className="date">{post.publishDateTime.toLocaleDateString("ru-RU")}</p>
      <div dangerouslySetInnerHTML={{__html: post.previewText}}/>
      <Link to={`/blog/${post.slug}`}><p className="further">Читать дальше</p></Link>
    </div>
  );
}

export default PostPreview;
