import React from 'react';
import {BrowserRouter as Router, Switch, Route, NavLink} from "react-router-dom";
import './App.css';
import Blog from "./blog/Blog";
import Post from "./blog/Post";
import Magazine from "./magazine/Magazine";
import About from "./about/About";
import Contacts from "./contacts/Contacts";
import NotFound from "./NotFound";

const isBlog = (_, location) => location.pathname === '/' || location.pathname.indexOf('/blog') === 0;

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <a href="/"><img width="300" src="/logo.svg" alt="Литературное агентство Нео-Лит" /></a>
          <span style={{"flexGrow": "2"}} />
          <NavLink className="nav" isActive={isBlog} to="/">Блог</NavLink>
          <NavLink className="nav" to="/magazine">Журнал</NavLink>
          <NavLink className="nav" to="/about">Об агентстве</NavLink>
          <NavLink className="nav" to="/contacts">Контакты</NavLink>
        </header>
        <div className="main">
          <Switch>
            <Route path="/" exact component={Blog} />
            <Route path="/blog/:postId" exact component={Post} />
            <Route path="/magazine" component={Magazine} />
            <Route path="/about" component={About} />
            <Route path="/contacts" component={Contacts} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
      <div className="copyright">© А. А. Самойлова, 2019—2020</div>
    </div>
  );
}

export default App;
