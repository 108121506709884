import React from 'react';
import './Contacts.css';

function Contacts() {
  return (
    <div className="Contacts">
      <h2>Контакты</h2>
      <div itemScope itemType="http://schema.org/Organization">
        <p><span itemProp="name">Литературное агентство "Нео-Лит"</span></p>
        <p itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          Адрес: <span itemProp="addressLocality">Барнаул</span>, <span itemProp="streetAddress">Чернышевского ул., 59, 4-й этаж, кабинет 403</span>
        </p>
        <p>Телефон: <a href="tel:+79333113661" itemProp="telephone" target="_blank" rel="noopener noreferrer">8-933-311-3661</a></p>
        <p>Email: <a href="mailto:altay.neo-lit@yandex.ru" itemProp="email" target="_blank" rel="noopener noreferrer">altay.neo-lit@yandex.ru</a></p>
      </div>
      <div className="networkIcons">
        <a href="https://vk.com/agentstvo_neo_lit" target="_blank" rel="noopener noreferrer">
          <img src="/icons/vk.svg" alt="В контакте" />
        </a>
        <a href="https://www.facebook.com/agentstvoneolit" target="_blank" rel="noopener noreferrer">
          <img src="/icons/facebook.svg" alt="facebook" />
        </a>
        <a href="https://www.youtube.com/channel/UCGoOmBXpS8AVoRPzNd6nv-Q" target="_blank" rel="noopener noreferrer">
          <img src="/icons/youtube.svg" alt="YouTube" />
        </a>
        <a href="https://api.whatsapp.com/send?phone=79333113661" target="_blank" rel="noopener noreferrer">
          <img src="/icons/whatsapp.svg" alt="WhatsApp" />
        </a>
        <a href="https://telegram.im/@anna_kikona" target="_blank" rel="noopener noreferrer">
          <img src="/icons/telegram.svg" alt="Telegram" />
        </a>
      </div>
    </div>
  );
}

export default Contacts;